<template>
  <v-app id="inspire">
    <v-app-bar
      id="vpb"
      ref="vpb"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      dense
      app
      dark
      color="menu_topo"
      class=""
      style="left:0px;background-color:#11815b;"
      elevation="3"
    >
      <v-app-bar-nav-icon @click.stop="ocultarMenu()" />
      <!--<v-toolbar-title color="caixa_azul">
        <span class="hidden-sm-and-down ">Sistema Financeiro</span>
      </v-toolbar-title>-->

      <v-spacer />
      <!--<v-autocomplete
        class="mt-5"
        v-model="model"
        :items="itensMenu"
        :loading="isLoading"
        :search-input.sync="search"
        clearable
        filled
        rounded
        hide-details
        hide-selected
        item-text="name"
        item-value="symbol"
        label="Pesquisar no sistema"
        prepend-inner-icon="search"
        flat
        dense
      ></v-autocomplete>-->

      <img src="@/assets/topo_dashboard.png" alt="Ommini Financeiro" />
      <v-spacer></v-spacer>
      <v-avatar size="26px" class="mt-1" color="success">
        <v-icon dark>
          mdi-account-circle
        </v-icon>
      </v-avatar>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="logout()"
            v-bind="attrs"
            class="white--text"
            v-on="on"
            icon
          >
            <v-icon>mdi-location-exit</v-icon>
          </v-btn>
        </template>
        <span>Sair do sistema</span>
      </v-tooltip>
    </v-app-bar>
    <v-navigation-drawer
      prepend
      class="mt-2 white--text fundo"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      style="height:100v!important"
      floating
      fixed
    >
      <!--<template v-slot:prepend>
        <p class="textoTopo">Sistema Financeiro</p>
      </template>-->
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>

          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :key="item.text" :to="item.link" link>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-group :value="false" no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Cadastros</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(cad, i) in cadastros"
            :key="i"
            :to="cad.link"
            link
          >
            <v-list-item-icon>
              <v-icon v-text="cad.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="cad.text"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Financeiro</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(fin, i) in financeiro"
            :key="i"
            :to="fin.link"
            link
          >
            <v-list-item-icon>
              <v-icon v-text="fin.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="fin.text"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <template v-for="item in itemsFinal">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>

          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :key="item.text" :to="item.link" link>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <!-- <template v-slot:append>
        <div style="margin-left:30%;margin-top:10%;">
          <v-img width="85" height="38" src="@/assets/logo_interno.png"></v-img>
        </div>
      </template>-->
    </v-navigation-drawer>

    <router-view></router-view>
    <v-footer height="25" color="#899886" padless>
      <v-col class="text-right white--text" cols="12">
        Tecnologias para gerenciar e lucrar
      </v-col>
    </v-footer>
    <!--<v-system-bar lights-out class="d-flex" color="secondary">
      <v-spacer></v-spacer>

      <v-icon style="color:orange">mdi-account-circle-outline</v-icon>
      <span>{{ nome }}</span>

      <span class="ml-10" style="cursor:pointer;color:red" @click="logout()"
        >Sair do sistema</span
      >
      <v-icon style="color:red">mdi-exit-to-app</v-icon>
    </v-system-bar>-->
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },
  mounted() {
    this.nome = sessionStorage.getItem("nome");
    /*axios.defaults.headers.common["Authorization"] =
      "bearer " + sessionStorage.getItem("token");*/
  },
  methods: {
    logout() {
      window.sessionStorage.setItem("token", "");
      this.$router.push("/");
    },
    ocultarMenu() {
      this.drawer = !this.drawer;
      /*if (this.drawer) {
        //  document.getElementById("vpb").style.marginleft = "110px:important";
        // document.getElementById("vpb").style.background = "red";
        document.getElementById("vpb").className = "margem_out";
      } else {
        // document.getElementById("vpb").style.marginleft = "256px:important";
        //document.getElementById("vpb").style.top = "50";
        document.getElementById("vpb").className = "margem";
      }*/
    },
  },
  data: () => ({
    snackbar: true,
    model: "",
    itensMenu: [],
    loading: true,
    search: null,
    isLoading: false,
    dialog: false,
    drawer: null,
    nome: "",
    empresas: [{ text: "Empresa1" }, { text: "Tecnoweb" }],
    items: [
      {
        icon: "mdi-chart-bar",
        text: "Painel Principal",
        link: "/dashboard/principal",
      },

      /* {
        icon: "mdi-barcode",
        text: "Lista de Produtos",
        link: "/dashboard/lista_produtos",
        //link: "/dashboard/produtos_sgt",
      },*/
    ],
    financeiro: [
      {
        icon: "mdi-clipboard-edit-outline",
        text: "Lancamentos",
        link: "/dashboard/lancamentos",
      },
      {
        icon: "mdi-clipboard-text-search-outline",
        text: "Consulta Lancamentos",
        link: "/dashboard/consulta_lancamentos",
      },

      {
        icon: "mdi-finance",
        text: "Resultado Operacional",
        link: "/dashboard/resultado_operacional",
      },
      {
        icon: "mdi-script-text-outline",
        text: "Extrato",
        link: "/dashboard/extrato",
      },

      /* {
        icon: "mdi-barcode",
        text: "Lista de Produtos",
        link: "/dashboard/lista_produtos",
        //link: "/dashboard/produtos_sgt",
      },*/
    ],
    cadastros: [
      {
        icon: "mdi-store",
        text: "Dados Empresa",
        link: "/dashboard/cadastro/empresa",
      },
      {
        icon: "mdi-hexagon-multiple-outline",
        text: "Grupos Financeiros",
        link: "/dashboard/cadastro/grupo_financeiro",
      },

      {
        icon: "mdi-cog-outline",
        text: "Status-Grupo Financeiro",
        link: "/dashboard/cadastro/status",
      },
      {
        icon: "mdi-cash",
        text: "Contas Bancárias",
        link: "/dashboard/cadastro/contas",
      },
      {
        icon: "mdi-truck-fast-outline",
        text: "Fornecedores",
        link: "/dashboard/cadastro/fornecedores",
      },
      {
        icon: "mdi-content-copy",
        text: "Historico Padrão",
        link: "/dashboard/cadastro/historico_padrao",
      },
      {
        icon: "mdi-account-group",
        text: "Funcionários",
        link: "/dashboard/cadastro/funcionarios",
      },
      {
        icon: "mdi-account-lock",
        text: "Permissões",
        link: "/dashboard/cadastro/permissoes",
      },
    ],
    itemsFinal: [
      {
        icon: "mdi-location-exit",
        text: "Sair",
        link: "/",
      },

      /* {
        icon: "mdi-barcode",
        text: "Lista de Produtos",
        link: "/dashboard/lista_produtos",
        //link: "/dashboard/produtos_sgt",
      },*/
    ],
  }),
};
</script>
<style scoped>
.margem {
  left: 30px;
}
.margem_out {
  margin-left: 256px;
}
.textoTopo {
  color: #fff;
  font-weight: 600;
  font-size: 30px;
  text-transform: unset;
  font-style: italic;
  font-family: Roboto;
}
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 10px;
}
.v-application--is-ltr
  .v-list-group--no-action.v-list-group--sub-group
  > .v-list-group__items
  > .v-list-item {
  padding-left: 40px;
}
.alert {
  margin-bottom: 0px !important;
}
</style>
